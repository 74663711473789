/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(#023382, #136cfb);
  border-radius: 10px;
}
.error-message {
  color: red;
}
.form-control::placeholder {
  color: #161616;
  opacity: 1;
}
textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px));
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  /* color: #212529; */
  background: none;
  /* background-color: transparent; */
  background-clip: padding-box;
  border: 1px solid #000000de;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea {
  resize: vertical;
}
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.form-label {
  margin-bottom: 0.5rem;
}
label {
  display: inline-block;
}
fieldset {
  /* display: block;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
  padding-block-start: 0 !important;
  padding-inline-start: 0 !important;
  padding-inline-end: 0 !important;
  padding-block-end: 0 !important;
  min-inline-size: min-content;
  border-width: 0 !important;
  border-style: none !important;
  border-color: transparent !important;
  border-image: initial; */
}

/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
.FlexContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  height: 100%;
  overflow: auto;
  flex-direction: column;
}
.FlexContainer div {
  width: 100px;
  height: 100px;
  margin: 5px;
  background-color: lightblue;
}
.img-responsive-1 {
  width: 100%;
  max-width: 250px;
  height: auto;
}
.img-responsive {
  width: 100%;
  height: auto;
}
@media print {
  .MuiDrawer-root.MuiDrawer-docked.css-4qcrfc-MuiDrawer-docked,
  .css-1figyp3-MuiPaper-root-MuiAppBar-root,
  .MuiButtonBase-root-MuiIconButton-root {
    display: none;
  }
}
